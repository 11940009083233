import React, { useContext } from 'react';
import AuthService from '../services/auth';

const AuthServiceContext = React.createContext<{ config: Record<string, any> | null }>({
  config: null,
});

export const AuthServiceProvider: React.FC<{ children: React.ReactNode; config?: Record<string, any> | any }> = ({
  children,
  config,
}) => {
  return <AuthServiceContext.Provider value={{ config }}>{children}</AuthServiceContext.Provider>;
};

export const useAuthService = () => {
  const { config } = useContext(AuthServiceContext);
  if (!AuthService.getInstance) {
    return AuthService.createInstance(config);
  }
  return AuthService.getInstance;
};
