import { ComponentType, FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Profile } from '../models/authModel';

type Props = {
  profile: Profile;
  component: ComponentType<{ logout?: () => void }>;
  [key: string]: any;
};

export const PublicRoute: FC<Props> = ({ component: Component, profile, logout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        profile ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <Component logout={logout} {...props} />
        )
      }
    />
  );
};
