import { Spin } from 'antd';
import './loader.css';

type Props = {
  transparent?: boolean;
};

export const Loader = ({ transparent }: Props) => {
  return (
    <div className="wrapper">
      <Spin />
    </div>
  );
};
