import { Form, Switch, Typography } from 'antd';
import styles from './styles.module.scss';

const { Text } = Typography;
type Props = {
  isModal: boolean;
  permission?: any;
};

export const AnalysisFormItems = ({ isModal, permission }: Props) => {
  return (
    <div className={isModal ? styles.manageColumn : styles.manage}>
      <div className={styles.item}>
        <Text type="secondary">View: </Text>
        <Form.Item
          valuePropName="checked"
          name={['abc_analysis_barcode']}
          label="Barcode ABC analysis"
          labelCol={{ span: isModal ? 23 : 17.4 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['out_of_stock_barcode']}
          label="Barcode Out of stock"
          labelCol={{ span: isModal ? 21.1 : 17.4 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['price_elasticity']}
          label="Price elasticity"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['product_comparison']}
          label="Product comparison"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['coexistence']}
          label="Co-Existence"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['coexistence_trends']}
          label="Co-Existence Trends"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['vpo_segmentation']}
          label="VPO-Segmentation"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name={['vpo_trend']}
          label="VPO-Segmentation Trends"
          labelCol={{ span: isModal ? 16 : 12.5 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        {permission === 601 && (
          <Form.Item
            valuePropName="checked"
            name={['basket_analysis']}
            label="Basket Analysis"
            labelCol={{ span: isModal ? 16 : 12.5 }}
            labelAlign="left"
          >
            <Switch />
          </Form.Item>
        )}
      </div>

      <div className={!isModal ? styles.item : ''}>
        <Text type="secondary">View buying behavior chart:</Text>
        <Form.Item
          valuePropName="checked"
          name={['buying_behavior_price']}
          label="Price"
          labelCol={{ span: isModal ? 3 : 7 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['buying_behavior_week']}
          label="Week"
          labelCol={{ span: isModal ? 2.5 : 7 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={['buying_behavior_volume']}
          label="Volume"
          labelCol={{ span: isModal ? 3.1 : 7.2 }}
          labelAlign="left"
        >
          <Switch />
        </Form.Item>
      </div>
    </div>
  );
};
