import { Form, Switch } from 'antd';

type Props = {
  isModal: boolean;
};

export const ShareByCitiesFormItems = ({ isModal }: Props) => {
  return (
    <>
      Market share:
      <Form.Item
        valuePropName="checked"
        name={['market_share_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['market_share_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['market_share_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      Trends share:
      <Form.Item
        valuePropName="checked"
        name={['share_trends_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['share_trends_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['share_trends_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      Comparison share:
      <Form.Item
        valuePropName="checked"
        name={['share_comparison_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['share_comparison_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['share_comparison_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
    </>
  );
};
