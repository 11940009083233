import { AxiosRequestConfig } from 'axios';
import { PageQueryParams } from '../models';
import fetchClient from './axiosInstance';

interface LocalCompanyQueryParams extends PageQueryParams {
  category?: string | number;
  all?: boolean;
  country?: string;
  ids?: string;
  is_active?: boolean;
  search?: string;
  subcategory?: string;
}

export default class LocalCompanyService {
  static async getLocalCompanies(params?: LocalCompanyQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/companies/local/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getLocalCompany(id: string | number, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/companies/local/${id}/`, { ...customConfig }).then((res) => res.data);
  }

  static async editLocalCompany(id: string | number, body: any, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/companies/local/${id}/`, body, { ...customConfig });
  }

  static async deleteLocalCompany(id: string | number, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/companies/local/${id}/`, { ...customConfig });
  }

  static async createLocalCompany(body: any, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/companies/local/`, body, { ...customConfig });
  }
}
