import { useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import AuthService from '../../../services/auth';

type Props = {
  onFilterChange?: () => void;
};

export const ChangePassword = ({ onFilterChange }: Props) => {
  const [filtersForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onFinish = (values: any) => {
    setLoading(true);
    AuthService.changePassword(values?.current_password, values?.new_password)
      .then((res) => {
        onFilterChange();
      })
      .catch((err) => setError(err?.message))
      .finally(() => setLoading(false));
  };

  return (
    <Form form={filtersForm} layout="vertical" onFinish={onFinish}>
      <Form.Item name="current_password" rules={[{ required: true, message: 'Please input your current password!' }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Enter current password"
          disabled={loading}
        />
      </Form.Item>

      <Form.Item name="new_password" rules={[{ required: true, message: 'Please input your new password!' }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Enter new password"
          disabled={loading}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
      {error && <p className="error">{error}</p>}
    </Form>
  );
};
