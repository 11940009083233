import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';
import { AuthResponse, Profile } from '../models/authModel';
import EventEmitter from 'events';

export enum AuthStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
}
export default class AuthService {
  static httpClient: AxiosInstance;
  private static _instance: AuthService;
  public loginStatus = new EventEmitter();
  private static _profile: any;

  constructor(private config?: any) {}

  static createInstance(config: any) {
    AuthService._instance = new AuthService(config);
    AuthService.createHttpClient();
    AuthService.httpClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      AuthService.getInstance.refresh
    );
    return AuthService._instance;
  }

  static get getInstance() {
    return AuthService._instance;
  }

  static createHttpClient(): AxiosInstance {
    if (AuthService.httpClient) {
      return AuthService.httpClient;
    }
    AuthService.httpClient = axios.create();
    return AuthService.httpClient;
  }

  static get getHttpClient(): AxiosInstance {
    return AuthService.httpClient || AuthService.createHttpClient();
  }

  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return fetchClient.post<AuthResponse>('/api/v1/token/', { email, password });
  }

  static async resetPassword(email: string): Promise<AxiosResponse<AuthResponse>> {
    return fetchClient.post<AuthResponse>('/api/v1/users/reset_password/', { email });
  }

  static async setPassword(new_password: string, token: string) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/users/set_password/`, { new_password }, config);
  }

  static async changePassword(current_password: string, new_password: string) {
    return fetchClient.post(`${process.env.REACT_APP_BASE_URL}/api/v1/users/change_password/`, {
      current_password,
      new_password,
    });
  }

  static async getUser(): Promise<Profile> {
    return fetchClient.get<Profile>('/api/v1/users/me/').then((res) => {
      AuthService._profile = res?.data;
      return res?.data;
    });
  }

  public refresh = async (axiosError?: AxiosError) => {
    if (axiosError && axiosError?.response?.status === 401) {
      try {
        await fetchClient.post<AuthResponse>('/api/v1/token/refresh/', {});
        return fetchClient.request(axiosError?.config);
      } catch (error) {
        AuthService.getInstance.loginStatus.emit(AuthStatus.UNAUTHORIZED, error);
      }
    }
    return Promise.reject(axiosError?.response?.data);
  };

  public getProfile(): Promise<Profile> {
    if (AuthService._profile) return Promise.resolve(AuthService._profile);
    return fetchClient.get<Profile>('/api/v1/users/me/').then((res) => {
      AuthService._profile = res?.data;
      return res?.data;
    });
  }
}
